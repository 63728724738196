<template>
  <div
    class="gamelist-body"
    ref="gamelistBody"
    :style="gamelistBodyConfig"
  >
    <slot></slot>
  </div>
</template>

<script>
import { getApp } from '@/assets/js/mixins/common/GetApp'
import { detectDevice } from '@/assets/js/mixins/base/DetectDevice'
import { elementExists } from '@/assets/js/mixins/base/ElementExists'

export default {
  name: 'gamelist-body',

  inject: {
    gamelistConfig: {}
  },

  mixins: [
    detectDevice,
    elementExists
  ],


  computed: {
    gamelistBodyConfig () {
      let result
      let width = this.gamelistConfig.body_w
      let gamelistWidth = this.gamelistConfig.gamelist_w

      if (gamelistWidth > width) {
        result = `${gamelistWidth}px`
      } else {
        result = `${width}px`
      }

      return {
        width: result
      }
    }
  }

}
</script>
